import { render } from "./InitialCampaignList.vue?vue&type=template&id=c180dc16"
import script from "./InitialCampaignList.vue?vue&type=script&lang=ts"
export * from "./InitialCampaignList.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QTd,QToggle,QBtn,QChip,QSpace,QSelect,QMenu,QList,QItem,QItemSection,QDialog});qInstall(script, 'directives', {ClosePopup});
